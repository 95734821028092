import React, { useState } from 'react';
import "./ResourcesFilters.css";

export const ResourcesFilters = (props: any) => {

    // mobile device open/close ResourceFilters
    const [navbarOpen, setNavbarOpen] = useState(false);

    // build navigation html tree based on children
    const navigation = (path: string, children: any) => {

        if (!children || path.split("___").length > 2) {
            return;
        }


        const navigationChildren = children.map((resource: any, index: number) => {
            let currentPath = path + (path ? "___" : "") + resource.name;
            let className = path === '' ? "resources-filters__group" : 'w-full mt-1';

            return resource.type ? '' : <li
                key={index}
                className={className}>
                <label className={props.resources.children.map((item: any) => item.name).indexOf(resource.name) > -1 ? "font-semibold text-gray-800" : ""}>
                    <input
                        className="gl_checkbox"
                        type="checkbox"
                        value={currentPath}
                        onChange={props.onchange}
                        checked={resource.checked ? true : false}
                    /> {resource.name}
                </label>

                {navigation(currentPath, resource.children)}
            </li>
        });



        return (
            <div className={path ? 'px-2 lg:px-6' : ''}>
                <ul>
                    {navigationChildren}
                </ul>
            </div>
        );
    }

    return (

        <div className="w-full border-b bg-neutral-100 border-neutral-200 text-gray-600 overflow-y-scroll pr-5 custom-scrollbar pl-2 max-h-full">
            <button
                className="flex justify-between w-full
                            cursor-pointer text-lg leading-none px-3 py-1
                            border border-solid border-transparent bg-transparent 
                            md:hidden 
                            outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
            >
                <span>Filters</span>
                <i className={navbarOpen ? "fas fa-caret-up" : "fas fa-caret-down"}></i>
            </button>
            <div className={(navbarOpen ? " " : " hidden md:block")}>

                <div className="resources-filters">
                    {navigation('', props.resources.children)}
                </div>
            </div>
        </div>
    );



};