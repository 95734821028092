import React, { useEffect, useRef } from 'react';
import './Bubble.css';
import * as d3 from 'd3';
import { PortalIcon } from '../icons/Portalcon';
import { ToolIcon } from '../icons/ToolIcon';
import { DbIcon } from '../icons/DbIcon';

export const Bubble = (props) => {

    const d3Container = useRef(null);



    const nodesRef = useRef(null);
    const circleRef = useRef(null);
    const zeroZoom = useRef(null);
    /* The useEffect Hook is for running side effects outside of React,
        for instance inserting elements into the DOM using D3 */
    useEffect(
        () => {
            if (props.resources && d3Container.current) {
                //log('Bubble.useEffect');
                //const root=props.resources;
                const svg = d3.select(d3Container.current);
                const margin = 20;
                const diameter = props.diameter;

                //const diameter = +svg.attr("width");
                const g = svg.append("g").attr("transform", "translate(" + diameter / 2 + "," + diameter / 2 + ")");


                const color = d3.scaleLinear()
                    .domain([-1, 5])
                    .range(["hsl(152,80%,80%)", "hsl(228,30%,40%)"])
                    .interpolate(d3.interpolateHcl);

                const pack = d3.pack()
                    .size([diameter - margin, diameter - margin])
                    .padding(2);
                const root = d3.hierarchy(props.resources)
                    .sum(function (d) { return 1000; })
                    .sort(function (a, b) { return b.value - a.value; });



                let focus = root,
                    nodes = pack(root).descendants(),
                    view;

                nodesRef.current = nodes;

                let circle = g.selectAll("circle")
                    .data(nodes)
                    .enter()
                    .append("circle")
                    .attr("id", function (d) {
                        let id = d.data.name;
                        if (d.parent) {
                            id = d.parent.data.name + "___" + id;
                            if (d.parent.parent) {
                                id = d.parent.parent.data.name + "___" + id;
                                if (d.parent.parent.parent) {
                                    id = d.parent.parent.parent.data.name + "___" + id;
                                    if (d.parent.parent.parent.parent) {
                                        id = d.parent.parent.parent.parent.data.name + "___" + id;
                                    }
                                }
                            }
                        }
                        return "id_" + id;
                    })
                    .attr("class", function (d) {
                        return d.parent ? d.children ? "node" : "node node--leaf" : "node node--root";
                    })
                    .style("fill", function (d) {
                        if (d.children) {
                            return color(d.depth);
                        } else {
                            switch (d.data.type) {
                                case "Database":
                                    return '#ffc425';
                                case "Tool":
                                    return '#00b159';
                                case "Portal":
                                    return '#d11141';
                                default:
                                    return null;
                            }
                        }
                    })
                    .on("click", function (d) {
                        if (focus !== d) {
                            zoom(d);
                            d3.event.stopPropagation();
                        }
                    });

                circleRef.current = circle;

                let text = g.selectAll("text")
                    .data(nodes)
                    .enter();

                if (!props.hideLegend) {
                    text.append("text")
                        .attr("class", "label")
                        .style("fill-opacity", function (d) { return d.parent === root ? 1 : 0; })
                        .style("display", function (d) { return d.parent === root ? "inline" : "none"; })
                        .on("click", function (d) {
                            if (d.data.link) {
                                window.open(d.data.link, '_blank');
                                d3.event.stopPropagation();
                            }
                        })
                        .on("mouseover", function (d) {
                            if (d.data.link) {
                                d3.select(this)
                                    .attr("fill", "red");
                            }
                        })
                        .on("mouseout", function (d) {
                            if (d.data.link) {
                                d3.select(this)
                                    .attr("fill", "black");
                            }
                        })
                        .text(function (d) { return d.data.name; })

                    text.append("text")
                        .attr("dy", "35")
                        .attr("class", "description")
                        .style("fill-opacity", function (d) { return d.parent === root ? 1 : 0; })
                        .style("display", function (d) { return d.parent === root ? "inline" : "none"; })
                        .text(function (d) { return d.data.descLine1; });

                    text.append("text")
                        .attr("dy", "55")
                        .attr("class", "description")
                        .style("fill-opacity", function (d) { return d.parent === root ? 1 : 0; })
                        .style("display", function (d) { return d.parent === root ? "inline" : "none"; })
                        .text(function (d) { return d.data.descLine2; });

                    text.append("text")
                        .attr("dy", "75")
                        .attr("class", "description")
                        .style("fill-opacity", function (d) { return d.parent === root ? 1 : 0; })
                        .style("display", function (d) { return d.parent === root ? "inline" : "none"; })
                        .text(function (d) { return d.data.descLine3; });
                }

                let node = g.selectAll("circle,text");

                svg.style("background", "white")
                    .on("click", function () { zoom(root); });

                zoomTo([root.x, root.y, root.r * 2 + margin]);

                function zoom(d) {
                    if (props.hideTitles) {
                        return;
                    }
                    zeroZoom.current = d.data.name;
                    console.log('Zoom', d, zeroZoom.current);
                    props.onzoom(d);
                    focus = d;

                    let transition = d3.transition()
                        .duration(d3.event.altKey ? 7500 : 750)
                        .tween("zoom", function (d) {
                            let i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2 + margin]);
                            return function (t) { zoomTo(i(t)); };
                        });

                    transition.selectAll(".description")
                        .filter(function (d) {
                            if (d.height === 0) {
                                return d;
                            }
                            return null;
                        })
                        .style("fill-opacity", function (d) {
                            if (d.height === 0 && d === focus) {
                                return 1
                            } else {
                                return 0;
                            }
                        })
                        .on("end", function (d) {
                            if (d.height === 0 && d === focus) {
                                this.style.display = "inline";
                            } else {
                                this.style.display = "none";
                            }
                        });


                    transition.selectAll(".label")
                        .filter(function (d) {
                            if (d.height !== 0) {
                                return d.parent === focus || this.style.display === "inline";
                            } else {
                                return d;
                            }
                        })
                        .style("fill-opacity", function (d) {
                            if (d.height !== 0) {
                                return d.parent === focus ? 1 : 0;
                            } else {
                                return 1;
                            }
                        })
                        .on("start", function (d) {
                            if (d.parent === focus) {
                                this.style.display = "inline";
                            }

                        })
                        .on("end", function (d) {
                            if (d.height === 0) {
                                if (d === focus || d.parent === focus) {
                                    this.style.display = "inline";
                                } else {
                                    this.style.display = "none";
                                }
                            } else {
                                if (d.parent !== focus) this.style.display = "none";
                            }

                        });
                }

                function zoomTo(v) {
                    let k = diameter / v[2]; view = v;
                    node.attr("transform", function (d) { return "translate(" + (d.x - v[0]) * k + "," + (d.y - v[1]) * k + ")"; });
                    circle.attr("r", function (d) { return d.r * k; });
                }
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])


    useEffect(() => {
        //console.log('useEffect.checkedPath', props.checkedPath);

        let id = "id_Resources" + (props.checkedPath ? "___" + props.checkedPath : "");
        //console.log('useEffect.checkedPath.2', id, circleRef.current._groups[0]);

        let selectedCircle = circleRef.current._groups[0].find(circle => circle.id === id);
        //console.log('useEffect.checkedPath.selectedCircle', selectedCircle);
        if (selectedCircle) {
            selectedCircle.dispatchEvent(new Event('click'));

        }
    }, [props.checkedPath]);


    const legend = <div className="order-2 flex justify-around mx-8
                                    p-3 pb-2 right-0 float-right text-gray-500 d:none text-sm
                                    lg:bg-neutral-100 lg:block lg:absolute lg:mx-0
                                    " >
        <div className="flex mb-2 align-middle">
            <div className="w-4 3xl:w-5 mr-1">
                <PortalIcon className="w-10" svgClassName="text-red-600" />
            </div>
            Portal
        </div>
        <div className="flex mb-2 align-middle">
            <div className="w-4 3xl:w-5 mr-1">
                <ToolIcon svgClassName="text-green-500" />
            </div>
            Tool
        </div>
        <div className="flex mb-2 align-middle">
            <div className="w-4 3xl:w-5 mr-1">
                <DbIcon svgClassName="text-yellow-500" />
            </div>
            Database
        </div>
    </div >;

    return (
        <div className=" flex flex-col lg:block   lg:relative">
            {props.hideLegend ? '' : legend}
            <div className="order-1">
                <svg ref={d3Container} width={props.diameter} height={props.diameter}></svg>
            </div>
        </div>
    );

};