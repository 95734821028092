import React from "react";

export const AboutWheelText = (props: any) => {
  return (
    <div>
      <p>
        Glyco@Expasy is intended as a solution to piecing the disparate information together. The coverage of our resources is designed to reflect the situation at the cell surface where glyconjugates and glycan-binding proteins functionally interact. The present collection promotes  interactive and interconnected tools that support hypothesis building. The dependency wheel is built to highlight such connectivity. Hovering over a resource name prompts the fading of all unrelated resource names.
      </p>
      <br />
      <h2 className="mt-3">Licence:</h2>
        The information on this portal are licenced under CC-BY-4.0. For more information on the terms of use of each resource, please refer to the resources' individual pages.
      <br /><br />
      The Glycomics@Expasy initiative is described <a className="underline" target="_blank" rel="noopenner noreferrer" href="https://doi.org/10.1074/mcp.RA118.000799">here</a>.
    </div>
  );
};
