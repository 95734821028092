import React, { useState, useRef, useEffect } from "react";

import { Link } from "react-router-dom";
import { Wheel } from "./Wheel";

export const ResourcesIntegration = (props: any) => {

    const [diameter, setDiameter] = useState(0);
    useEffect(() => {
        setDiameter(window.innerWidth > window.innerHeight
            ? window.innerHeight
            : window.innerWidth);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    return (
        <section className="h-full">
            <div className="">
                <div className="text-right italic">See <Link className="underline" to="/about-wheel">About</Link> for more details.</div>
            </div>
            {diameter > 0
                ? <Wheel
                    resources={props.resources}
                    diameter={diameter}
                /> : ''}
        </section>
    );
}

