import { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Bubble } from "../home/Bubble";

import { AboutBubbleText } from "./AboutBubbleText";
import { AboutWheelText } from "./AboutWheelText";
export const About = (props: any) => {


    const bubbleContainer = useRef<HTMLInputElement>(null);
    const [resources, setResources] = useState({});
    const [diameter, setDiameter] = useState(0);

    // only first render load resources.json
    useEffect(() => {

        const resourcesJson: any = props.resources;
        setResources(cloneDeep(resourcesJson));
        setDiameter(bubbleContainer.current ? bubbleContainer.current.offsetWidth : 0);


    },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    return (
        <div className="lg:flex">
            <div className="lg:w-1/2 lg:pr-6">


                <div className="bg-neutral-100 border-t border-neutral-200 p-4 mb-8">
                    <div className="float-right w-48 h-56 text-center ml-2">
                        <div ref={bubbleContainer}>
                            {diameter
                                ? <Bubble
                                    hideLegend="true"
                                    hideTitles="true"
                                    diameter={diameter}
                                    resources={resources}
                                /> : ''}
                        </div>
                        <Link to="/about-bubble">(click to enlarge)</Link>
                        <div>



                        </div>
                    </div>
                    <h2 className="mt-0">Resource classification</h2>
                    <AboutBubbleText></AboutBubbleText>
                </div>
            </div>
            <div className="lg:w-1/2 lg:pl-3">

                <div className="bg-neutral-100 border-t border-neutral-200 p-4 mb-8">
                    <div className="float-right w-48 h-56 text-center ml-2">

                        <div className="text-center">

                            <Link to="/about-wheel">
                                <img src={process.env.PUBLIC_URL + "/images/wheel.jpg"} alt="" />
                                (click to enlarge)
                            </Link>
                        </div>
                    </div>
                    <h2 className="mt-0">Interconnection of the resources</h2>
                    <AboutWheelText></AboutWheelText>
                </div>



            </div>
        </div >

    );
}