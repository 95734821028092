import React from 'react';
import { DbIcon } from '../icons/DbIcon';
import { DoiIcon } from '../icons/DoiIcon';
import { PortalIcon } from '../icons/Portalcon';
import { ToolIcon } from '../icons/ToolIcon';

export const Resources = (props: any) => {

    // wheter resource contains children with checked === true
    const containsChekedChildren = (resource: any): boolean => {
        if (resource.children) {
            for (let i in resource.children) {
                let hasChildrenCecked = containsChekedChildren(resource.children[i]);
                if (hasChildrenCecked) {
                    return hasChildrenCecked;
                }
            }
        }
        return resource.checked === true ? true : false;
    }


    // show filtered resources
    const showResources = (path: string, children: any) => {
        if (!children) {
            return;
        }

        const childrenResource = children.map((resource: any, index: number) => {
            let currentPath = path + (path ? "___" : "") + resource.name;

            if (resource.children) {
                if (!containsChekedChildren(props.resources) || containsChekedChildren(resource)) {
                    return (
                        <div key={index}>
                            {resource.children && resource.children[0].type ? <h2 className="text-md pl-2 text-black">{resource.name}</h2> : ''}
                            <div>{showResources(currentPath, resource.children)}</div>
                        </div>
                    );
                }
            }

            if (!containsChekedChildren(props.resources) || resource.checked) {
                let expasy_link = resource.expasy_link.trim();
                return <div key={index} className={"resource-box flex justify-between " + (resource.is_sib ? "resource-sib" : "")}>
                    <div className="w-11/12">

                        <div className="font-semibold pb-1">
                            <label>
                                <input type="checkbox"
                                    onChange={() => props.onchange(currentPath)}
                                    checked={props.checkedPath === currentPath ? true : false}
                                /> {resource.name}
                            </label>
                        </div>

                        <div className="font-normal leading-4 text-sm text-gray-600 pb-1">{resource.descLine1} {resource.descLine2} {resource.descLine3}</div>
                        {(resource.link && resource.link !== expasy_link ? <div className="mt-1 text-sm">
                            <a className="underline" rel="noreferrer" target="_blank" href={resource.link}>Link</a>
                        </div> : '')}
                        {(expasy_link ?
                            <div className="mt-1 text-sm text-black">
                                <a className="underline" rel="noreferrer" target="_blank" href={expasy_link}>Browse also this resource on Expasy</a>
                            </div> : '')}
                    </div>
                    <div className="flex flex-col pt-2 pr-1 text-gray-600 w-6" >
                        {(resource.type === 'Portal' ? <div className="pb-4"><PortalIcon /></div> : '')}
                        {(resource.type === 'Tool' ? <div className="pb-4"><ToolIcon /></div> : '')}
                        {(resource.type === 'Database' ? <div className="pb-4"><DbIcon /></div> : '')}
                        {(resource.has_doi ? <div className="pb-4"><a rel="noreferrer" target="_blank" href={resource.has_doi}><DoiIcon /></a></div> : '')}
                        {(resource.is_sib ? <div className="pb-4"><img title="SIB Resource" src={process.env.PUBLIC_URL + "/images/sib-logo.svg"} alt="" /></div> : '')}
                    </div>
                </div>
            }

            return null;

        });


        return (
            <div>{childrenResource}</div>
        );
    }

    return (
        <div className="overflow-y-scroll pr-5 custom-scrollbar pl-2 h-full">
            {props.resources ? showResources('', props.resources.children) : ''}
        </div>
    );



};