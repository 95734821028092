
import { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Bubble } from "../home/Bubble";

import { AboutBubbleText } from "./AboutBubbleText";


export const AboutBubble = (props: any) => {
    const bubbleContainer = useRef<HTMLInputElement>(null);
    const [resources, setResources] = useState({});
    const [diameter, setDiameter] = useState(0);

    useEffect(() => {

        //console.log('fetch.then: ', data);
        const resourcesJson: any = props.resources;
        setResources(cloneDeep(resourcesJson));
        setDiameter(bubbleContainer.current ? bubbleContainer.current.offsetWidth : 0);


    }, []);

    return (



        <div className="lg:flex">

            <div className="max-h-full
                    lg:w-1/2 lg:mr-8 ">
                <div className="bg-neutral-100 border-t border-neutral-200 p-4 mb-8">
                    <div className="flex justify-end md:justify-between items-center flex-wrap mb-8">
                        <h2 className="mt-0">Resource classification</h2>
                        <div>
                            <Link className="bg-white border-2 border-blue-300 py-2 px-3 rounded text-sm no-underline
                                md:mr-3"
                                to="/">Go to the page</Link>
                        </div>
                    </div>

                    <AboutBubbleText></AboutBubbleText>
                </div>
            </div>
            <div ref={bubbleContainer}
                className="max-h-full ml-8 border-t border-neutral-200 pt-6
lg:w-1/2 ">
                {diameter
                    ? <Link to="/">
                        <Bubble
                            resources={resources}
                            onzoom={() => { }}
                            diameter={diameter}
                        />
                    </Link> : ''}
            </div>
        </div>

    );
}