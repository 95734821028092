import React from "react";

export const Footer = (props: any) => {
    return (
        <div className="bg-gray-100 px-4 py-2 text-sm">
            <div className="container flex items-center">
                <div className="mx-5 w-1/4 md:w-auto">
                    <img src="./images/sib-mark.svg" alt="" />
                </div>
                <div className="pt-1 font-semibold">
                    This portal is operated by the <a className="underline" href="https://www.sib.swiss/frederique-lisacek-group">Proteomics Informatics Group</a> of the <a className="underline" href="https://www.unige.ch/">University of Geneva</a> and <a className="underline" href="https://www.sib.swiss">SIB Swiss Institute of Bioinformatics</a> | <a className="underline" href="https://www.sib.swiss/privacy-policy">Privacy Policy</a>
                </div>
            </div>
        </div>
    );
};
