import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
export const Header = (props: any) => {
  const location = useLocation();


  const [mobileMenuOpen, setMobileMenuOpen] = useState(true);
  const [helpSubmenu, setHelpSubmenu] = useState(true);

  return (
    <div>
      <div className="mx-2
                      md:mx-8">
        <div className="flex justify-between flex-wrap">

          <div className="pt-2 w-5/6 md:pt-6
            lg:w-auto">
            <Link to="/">
              <img
                className="inline-block"
                src={process.env.PUBLIC_URL + "/images/glyco-expasy.png"}
                alt="Expasy: Swiss Bioinformatics Resource Portal"
              /></Link>
          </div>
          <div className="lg:hidden w-1/6 text-right pt-4">
            <button onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="inline-block w-10 h-10 bg-gray-300 text-white p-1 rounded">
              <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"></path>
              </svg>
            </button>
          </div>
          <div className={(mobileMenuOpen ? 'hidden' : '')
            + " mt-2 mb-3 w-full bg-gray-100 "
            + " lg:block lg:w-auto lg:bg-white"
          }>
            <nav role="navigation" className="max-sm:w-full">
              <ul className="nav text-gray-700 justify-between
                            md:flex md:font-semibold">
                <li className={(location.pathname === '/' ? 'bg-neutral-200 ' : '')
                  + "px-2 border-b border-gray py-1 "
                  + "md:px-6  md:pt-6 lg:border-none"}>
                  <Link to="/">Resource thematic classification</Link>
                </li>
                <li className={(location.pathname === '/resource-integration' ? 'bg-neutral-200 ' : '')
                  + "px-2 border-b border-gray py-1 "
                  + "md:px-6  md:pt-6 lg:border-none"}>
                  <Link to="/resource-integration">Resource dependency wheel</Link>
                </li>
                <li className="hidden md:block border-neutral-200 border-r-2 mt-6 mb-6 ml-3">

                </li>
                <li className="flex 
                  md:justify-end md:pl-3 ">
                  <span className="dropdown block relative flex-grow">
                    <button onClick={() => setHelpSubmenu(!helpSubmenu)}
                      className={(['/media',
                        '/about',
                        '/about-wheel',
                        '/about-bubble'
                      ].indexOf(location.pathname) > -1 ? 'bg-neutral-100' : '')
                        + " pl-6 pr-3 py-1 md:py-6 font-normal w-full justify-between flex items-cente"}>
                      <span className="mr-1">Help</span>
                      <svg className="fill-current h-4 w-4 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /> </svg>
                    </button>
                    <ul className={
                      (helpSubmenu ? 'hidden' : '')
                      + " dropdown-menu  rounded right-0"
                      + " md:absolute md:bg-white md:border-neutral-200 md:border-l md:border-r md:border-b md:border-t md:text-sm md:text-gray-700"}>

                      <li className={(location.pathname === '/media' ? 'bg-neutral-200 ' : '')
                        + " pl-7 "
                        + " md:pl-0 md:border-neutral-200 md:border-b"}>
                        <Link className="md:bg-white md:hover:bg-gray-100 py-1 px-4 block whitespace-nowrap"
                          to="/media">Media</Link>
                      </li>

                      <li className={(location.pathname === '/about' ? 'bg-neutral-200 ' : '')
                        + " pl-7 "
                        + "  md:pl-0 md:border-neutral-200 md:border-b "}>
                        <Link className="md:bg-white md:hover:bg-gray-100 py-1 px-4 block whitespace-nowrap" to="/about">About</Link>
                      </li>

                      <li className="md:border-neutral-200 md:border-b  pl-7 md:pl-0">
                        <a className="md:bg-white hover:bg-gray-100 py-1 px-4 block whitespace-nowrap" href="https://www.expasy.org/contact" target="_blank" rel="noopenner">Contact</a>
                      </li>
                      <li className="pl-7 md:pl-0">
                        <a className="md:bg-white hover:bg-gray-100 py-1 px-4 block whitespace-nowrap "
                          href="https://www.expasy.org" target="_blank" rel="noopenner">Browse in Expasy</a>
                      </li>
                    </ul>
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="items-baseline pb-3 flex justify-end 
                      md:justify-between md:pb-6">
          <div className="hidden md:block font-bold md:pl-24 pr-2">
            {location.pathname === '/' ? "Zooming in on web-based glycoinformatics resources" : ''}
            {location.pathname === '/resource-integration' ? "Hover the mouse on a resource to highlight its links with other resources" : ''}
          </div>

          <div>
            <a href="https://twitter.com/intent/follow?screen_name=GlycomicsExpasy"
              className="text-white font-thin border border-blue-500 rounded text-center text-xs cursor-pointer inline-block px-2 mb-2
                md:w-44 md:text-xs md:py-1 md:mb-0"
              style={{ backgroundColor: '#59b1ec' }} >
              <img className="w-3 inline-block mr-1
                              md:w-5 "
                src={process.env.PUBLIC_URL + "/images/twitter-icon.png"}
                alt="Twitter" />
              <small className="md:hidden">Follow us</small>
              <span className="hidden md:inline">Follow @GlycomicsExpasy</span>
            </a>
          </div>
        </div>
      </div>
    </div >
  );
};
