import React, { useEffect, useRef, useState } from 'react';

import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import './App.css';
import { Header } from './components/Header';
import { Footer } from './components/Footer';

import { Home } from './components/home/Home';
import { Media } from './components/media/Media';
import { About } from './components/about/About';
import { AboutWheel } from './components/about/AboutWheel';
import { AboutBubble } from './components/about/AboutBubble';
import { ResourcesIntegration } from './components/wheel/ResourcesIntegration';
import { cloneDeep } from 'lodash';


function App() {
  const location = useLocation();
  const headerContainer = useRef<HTMLInputElement>(null);
  const footerContainer = useRef<HTMLInputElement>(null);

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [bubbleContainerHeight, setBubbleContainerHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);


  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      setHeaderHeight(headerContainer.current ? headerContainer.current.offsetHeight : 0);
      setFooterHeight(footerContainer.current ? footerContainer.current.offsetHeight : 0);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const [resources, setResources] = useState({ children: [] });

  useEffect(() => {

    fetch('/resources/bubble/resources.json',
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
    )
      .then(res => res.json())
      .then((data) => {
        //console.log('fetch.then: ', data);
        const resourcesJson: any = data;
        setResources(cloneDeep(resourcesJson));
      })
      .catch((error) => {
        console.error('error', error);
      });

  }, []);


  const onBubblContainerDimensionChangeHandled = (h: number) => {
    setBubbleContainerHeight(h);
  }

  const maxH = () => {

    if (windowDimensions.width < 768) {
      return "auto";
    }
    // console.log("H, bubbleContainerHeight", windowDimensions.height,
    //   (bubbleContainerHeight + headerHeight + footerHeight),
    //   headerHeight, bubbleContainerHeight, footerHeight);

    if (location && location.pathname === "/") {
      if (windowDimensions.height > bubbleContainerHeight + headerHeight + footerHeight) {
        return "100vh";
      }
      return bubbleContainerHeight + headerHeight + 100 + "px";
    } else if (location && location.pathname === '/resource-integration') {
      return 'auto';
    }
    return "auto";

  }

  return (
    <div className="max-container flex flex-col items-stretch text-gray-800 mx-auto"
      style={{ height: maxH() }}
    >

      <div ref={headerContainer}><Header location={location} /></div>

      {
        resources.children.length > 0
          ?
          <section className="mx-2 sm:mx-8 flex-1 sm:h-full sm:overflow-hidden">
            <Switch >
              <Route exact path="/">
                <Home resources={resources} onBubblContainerDimensionChange={onBubblContainerDimensionChangeHandled} />
              </Route>
              <Route path="/about">
                <About resources={resources} />
              </Route>
              <Route path="/about-wheel">
                <AboutWheel resources={resources} />
              </Route>
              <Route path="/about-bubble">
                <AboutBubble resources={resources} />
              </Route>
              <Route path="/resource-integration">
                <ResourcesIntegration resources={resources} />

              </Route>
              <Route path="/media">
                <Media />
              </Route>
            </Switch>
          </section> : ''

      }
      <div ref={footerContainer}><Footer /></div>
    </div>
  );
}

export default App;
