import React, { useEffect, useState } from "react";

import "./YoutubeEmbed.css";
import YoutubeEmbed from "./YoutubeEmbed.js";


declare const window: any;

export const Media = (props: any) => {

    const [readall, setReadall] = useState(false);

    const tollgeReadall = () => {
        setReadall(!readall);
    }


    useEffect(() => {
        if (window.twttr) {
            window.twttr.widgets.load();
        }
    }, []);
    return (
        <div className="max-h-full text-gray-500
                        flex flex-wrap lg:flex-nowrap">

            <div className="border-t border-neutral-200 pt-4 overflow-y-scroll max-h-full custom-scrollbar w-full order-2                            
                            lg:w-2/3 lg:mr-8 lg:order-none">
                <div className="mb-8 bg-neutral-100 border-t border-b border-neutral-200 
                                lg:flex">
                    <div className="lg:w-1/3 p-4">
                        <YoutubeEmbed embedId="26FnMtCTGUc" />
                    </div>
                    <div className="lg:w-2/3 p-4">
                        <h3 className="text-md font-semibold mb-2">SARS-Coronavirus-2 glycobiology</h3>
                        <p>
                            May 2020 -This short webinar explains — and demonstrates — the use of GlyConnect (https://glyconnect.expasy.org), the main glycoinformatics database hosted at SIB.
                            It contains information on glycoproteins and their associated glycans.
                            The SARS-CoV-2 spike protein was shown to be heavily glycosylated and as data is released, it is included in GlyConnect to highlight the latest knowledge on coronavirus glycobiology.
                            This information can be browsed and visualised with dedicated tools such as GlyConnect
                        </p>
                    </div>
                </div>


                <div className="mb-8 bg-neutral-100 border-t border-b border-neutral-200
                                lg:flex">
                    <div className="lg:w-1/3 p-4">
                        <YoutubeEmbed embedId="26FnMtCTGUc" />
                    </div>
                    <div className="lg:w-2/3 p-4">
                        <h3 className="text-md font-semibold mb-2">Glydin' : The network of glycoepitopes</h3>
                        <p>
                            May 2016 - Glydin’ (Glycan dynamics) is a tool to visualise and explore the relationships between glycoepitopes based on either their shared monosaccharide composition or the glycosyltransferase needed to get one from the other.
                            It was designed to (1) merge distinct sources of information and standardise the description glycoepitopes provided in different formats and (2) map the similarity between these entities in an interactive network.
                        </p>
                    </div>
                </div>

            </div>

            <div className="max-h-full border-t border-neutral-200 pt-4 overflow-y-auto order-1
                            lg:w-1/3 lg:ml-6 lg:order-none">
                <div className="bg-neutral-100 p-4 pt-6">
                    <div className="mb-4">
                        <YoutubeEmbed embedId="uV7rM3ehmf0" />
                        <h3 className="text-md font-semibold">Introduction to glycomics@ExPASy</h3>
                        <p className={readall ? 'pt-0' : 'max-h-20 pt-2 overflow-hidden'}>
                            May 2020 - Often enough the word glycoprotein is used yet the “glyco” part is hardly ever detailed.
                            This is for instance the case with viruses in which surface spiking proteins are abbreviated GP and numbered like HIV GP120, but remain depicted as a smooth fist-like structure.
                            In reality, glycan molecules decorate a broad variety of surface or secreted proteins and the literature is filled with unnoticed references focusing on the “glyco” part.
                            This course is an opportunity to update and extend your knowledge of glycoproteins through the use of bioinformatics resources collectively called “glycoinformatics”.
                            This course had an overview of the challenging questions raised by the study of glycans as non-template driven molecules and the solutions that are currently proposed to store, search, analyse and visualise the effect(s) of protein glycosylation on protein structure,
                            function(s) and interactions.
                        </p>
                        <button className="mt-1 text-sm float-right" onClick={tollgeReadall}>{readall ? 'Show less..' : 'Show more..'}</button>
                    </div>
                </div>


                <div className="mt-4 mx-4 mb-8">
                    <a className="twitter-timeline"
                        data-height="300"
                        data-chrome="nofooter noheader noborders transparent"
                        href="https://twitter.com/GlycomicsExpasy">
                    </a>
                </div>

            </div>
        </div>
    );
}