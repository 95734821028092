import React from "react";
import PropTypes from "prop-types";



const YoutubeEmbed = ({ embedId }) => (
  <div className="aspect-w-16 aspect-h-9">
    <iframe
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);


YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};


export default YoutubeEmbed;