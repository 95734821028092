import React, { useRef, useEffect, useState } from "react";
import './Wheel.css';
import * as d3 from 'd3';
import datacsv from './wheel-dependencies.csv';


// http://46.101.116.183/elixir-core-resources/
export const Wheel = (props) => {


  const d3Title = useRef(null);
  const wheel3D = useRef(null);
  const [matrix, setMatrix] = useState({});



  const getColorByResourceType = (type) => {
    switch (type) {
      case "Database":
        return '#ffc425';
      case "Tool":
        return '#00b159';
      case "Portal":
        return '#d11141';
      default:
        return '#ffffff';
    }
  }

  /*
  let colors = {};
  const colores_google = (n) => {
    let colores_g = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00", "#b82e2e", "#316395", "#994499", "#22aa99", "#aaaa11", "#6633cc", "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac"];
    return colores_g[n % colores_g.length];
  }
  */


  const process = (data) => {


    const width = props.diameter;
    const height = props.diameter;
    const outerRadius = Math.min(width, height) / 3.3; //3.5;
    const innerRadius = outerRadius - 20;



    //const formatPercent = d3.format(",.2f");

    const arc = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    d3.chord()
      .padAngle(.05)
      .sortSubgroups(d3.descending)
      .sortChords(d3.ascending);

    // FUNCTION EXPRESSIONS
    const chord = d3.chord()
      .padAngle(.05)
      .sortSubgroups(d3.descending)
      .sortChords(d3.descending);

    const path = d3.ribbon()
      .radius(innerRadius);


    const mouseover = function (d, i) {

      const groups = svg.selectAll(".group");
      groups.classed("fade", function (p) {
        return p.index !== i
          && data.matrix[i][p.index] === 0;
      });

      const chord = svg.selectAll(".chord");
      chord.classed("fade", function (p) {
        return p.source.index !== i
          && p.target.index !== i;
      });


      chord.classed("show", function (p) {
        return p.source.index === i
          || p.target.index === i;
      });

      d3.select(d3Title.current)
        .text(this.textContent);

    }

    const mouseout = function () {
      d3.select(d3Title.current).text("");
    }

    const chordMouseover = function () {
      if (this.classList.value.includes("fade")) {
        return;
      }
      d3.select(d3Title.current)
        .html(this.textContent.replace("\n", "<br>"));
    }

    const svg = d3.select(wheel3D.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("id", "circle")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");


    svg.append("circle")
      .attr("r", outerRadius)
      .style("fill", '#ffffff');

    //console.log('data.matrix', data);

    const apps = [];
    for (var i = 0; i < data.header.length; i++) {
      apps.push({
        "feeType": "Payments",
        "name": data.header[i].name,
        //"color": data.header[i].color  //colores_google(i) 
        "color": getColorByResourceType(data.header[i].type)
      })
    }

    // Compute the chord layout.    
    const group = svg.selectAll(".group")
      .data(chord(data.matrix).groups)
      .enter()
      .append("g")
      .attr("class", "group")
      .on("mouseover", mouseover)
      .on("mouseout", mouseout);



    group.append("path")
      .attr("id", function (d, i) {
        //console.log('id', d, i);
        return "group" + i; //d.source.index;
      })
      .attr("d", arc)
      .attr("class", "arc")
      .style("fill", function (d, i) {
        //console.log('fill', apps[d.source.index].color);
        return apps[i].color;
      });


    group.append("svg:text")
      .each(function (d) {
        d.angle = (d.startAngle + d.endAngle) / 2;
        //console.log('d.angle', d.angle, d);
      })
      .attr("dy", ".35em")
      .attr("text-anchor", function (d) {
        //console.log('text-anchor', d, d.angle > Math.PI ? "end" : null);
        return d.angle > Math.PI ? "end" : null;
      })

      .attr("transform", function (d) {
        return "rotate(" + (d.angle * 180 / Math.PI - 90) + ")"
          + "translate(" + (outerRadius + 6) + ")"
          + (d.angle > Math.PI ? "rotate(180)" : "");
      })

      .style("fill", function (d, i) {
        return apps[i].color;
      })
      .text(function (d, i) {
        return apps[i].name;
      });


    // Add the chords.
    const chords = svg.selectAll(".chord")
      //.data(layout.chords)
      .data(chord(data.matrix))
      .enter().append("path")
      .attr("class", "chord")
      .style("fill", function (d) {
        return apps[d.target.index].color;
      })
      .attr("d", function (d) {
        return path(d);
      })
      .on("mouseover", chordMouseover)
      .on("mouseout", mouseout);

    // Add an elaborate mouseover title for each chord.
    chords.append("title").html(function (d) {
      return apps[d.target.index].name + " and " + apps[d.source.index].name + " are linked";
    });

  }


  let categories = ['Portal', 'Glycan & Glycome', 'Glycan Binding', 'Glycoconjugates'];
  const headerArray = (children, datacsv, category = null) => {
    let arr = [];
    for (let i in children) {
      let child = children[i];
      if (child.children) {
        arr = arr.concat(headerArray(
          child.children,
          datacsv,
          categories.indexOf(child.name) > -1 ? child.name : category)
        );
      } else {
        if (datacsv.columns.find(el => {
          return el.trim() === child.name;
          //&& (child.name !== 'GlyGen' || child.type !== 'Portal');
        })
        ) {
          if (categories.indexOf(category) === -1) {
            categories.push(category);
          }
          arr.push({
            name: child.name,
            type: child.type,
            category: category,
            color: getColorByResourceType(child.type) //colores_google(categories.indexOf(category)) 
          });
        }
      }
    }
    return arr;
  }

  function sortByType(array) {
    let arr = [];
    arr = arr.concat(array.filter(item => item.type === 'Portal'));
    arr = arr.concat(array.filter(item => item.type === 'Database'));
    arr = arr.concat(array.filter(item => item.type === 'Tool'));
    return arr;
  }

  const typesColor = () => {
    if (!matrix.header) {
      return;
    }
    let cat = [];
    return matrix.header.map((item, index) => {
      if (cat.indexOf(item.type) === -1) {
        cat.push(item.type);
        return (
          <div key={index}
            className="flex items-center mb-2">
            <div className="w-5 h-5 mr-2 opacity-70" style={{ "backgroundColor": item.color }}></div>
            {item.type}
          </div >
        )
      }
    });
    //console.log('typesColor', categories);
    //return "XX: " + categories.join("; ");

  }

  useEffect(
    () => {

      console.log('wheel.resources', props.resources);
      d3.csv(datacsv)
        .then(function (datacsv) {
          console.log('datacsv', datacsv);

          let mymatrix = {
            header: sortByType(headerArray(props.resources.children, datacsv)),
            matrix: []
          };


          for (let k in datacsv) {
            let row = datacsv[k];
            if (k !== 'columns') {
              let resRowName = datacsv.columns[parseInt(k) + 1].trim();

              if (resRowName) {
                let nameValues = [];
                for (const [key, value] of Object.entries(row)) {
                  let resColName = key.trim();
                  if (resColName !== '') {
                    mymatrix.header.forEach((element, index) => {
                      if (element.name == resColName) {
                        nameValues[index] = parseInt(value ? value : 0);
                      }
                    });
                  }
                }

                mymatrix.header.forEach((element, index) => {
                  if (element.name === resRowName) {
                    mymatrix.matrix[index] = nameValues;
                  }
                });


              }

            }

          }

          console.log('mymatrix', mymatrix);

          setMatrix(mymatrix);
          process(mymatrix);
        }).catch(function (err) {
          throw err;
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


  return (
    <div className="wheel lg:flex justify-between -mx-8 ">

      <div className="mt-10                      
                      xl:-mt-10 
                      3xl:l:-mt-24 "
        ref={wheel3D}></div>


      <div className="lg:w-1/4 mt-5">

        <div ref={d3Title} className="mt-7 h-10 font-semibold" ></div>

        <div className="bg-gray-100 py-2 px-2 rounded">{typesColor()}</div>

      </div>



    </div>

  );
}

