import React from "react";

export const PortalIcon = (props: any) => {
	return (
		<svg className={"fill-current " + (props.svgClassName ? props.svgClassName : "text-gray-600")}
			version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 35.4" > <g>
				<g>
					<path d="M16,0C7.2,0,0,7.2,0,16c0,8.8,7.2,15.9,16,15.9c0.5,0,0.9,0,1.3-0.1c-0.2-0.3-0.4-0.6-0.6-0.9c-0.3-0.5-0.5-1.1-0.7-1.7
			c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0s0,0,0,0
			c-0.2-0.6-0.1-1.3,0.2-1.9c0-0.1,0.1-0.2,0.2-0.4c0,0,0,0,0,0l0,0c0.1-0.1,0.2-0.3,0.2-0.4v0c0,0,0,0,0,0l0,0
			c0.3-0.4,0.7-0.8,1.1-1c0,0,0,0,0,0s0,0,0,0c0-0.5,0-0.9,0.1-1.4c0-0.1,0-0.2,0-0.3H17v-5.9h0.6c0.2-0.7,0.8-1.3,1.8-1.3
			c0.2,0,0.4,0,0.6,0.1c0.1,0,0.2,0.1,0.4,0.1c0.5,0.2,0.9,0.6,1.1,1h1.5c-0.1,1.1-0.3,2.1-0.7,3.2c0.7,0,1.3,0.4,1.7,0.9
			c0.4-1.3,0.8-2.6,0.9-4h5c-0.1,2-0.7,4-1.6,5.8c0.5,0.5,0.8,1.2,0.8,1.9c0,0.1,0,0.1,0.1,0.2c1.8-2.6,2.8-5.6,2.8-8.9
			C32,7.1,24.8,0,16,0z M11.5,2.7c-0.8,0.9-1.7,2-2.4,3.4c-1-0.2-1.8-0.4-2.4-0.5C8.1,4.3,9.7,3.4,11.5,2.7z M10.1,8.3
			c1.4,0.2,3.1,0.4,4.8,0.5v5.9H8.5C8.6,12.3,9.2,10.2,10.1,8.3z M5.1,7.2c0.6,0.2,1.7,0.5,3,0.8c-0.9,1.9-1.5,4.2-1.6,6.7H2.1
			C2.3,11.9,3.4,9.3,5.1,7.2z M2,16.7h4.6c0.2,2.4,1,4.6,1.9,6.6c-1.2,0.3-2.4,0.6-3.7,1C3.2,22.1,2.2,19.5,2,16.7z M6.3,26
			c1.1-0.3,2.2-0.6,3.2-0.8c1,1.7,2.1,3.2,3,4.3C10.1,28.9,8,27.7,6.3,26z M15,29.2c-0.8-0.9-2.1-2.4-3.3-4.4
			c1.1-0.1,2.3-0.2,3.3-0.2V29.2z M15,22.6c-1.4,0-2.9,0.1-4.4,0.3c-1-1.9-1.7-4-2-6.2H15V22.6z M15,6.8c-1.4-0.1-2.6-0.2-3.8-0.3
			c1.5-2.3,3.1-3.8,3.8-4.4V6.8z M25.4,5.6c-0.9,0.3-1.9,0.5-2.8,0.7c-0.9-1.5-1.9-2.8-2.7-3.8C21.9,3.1,23.8,4.2,25.4,5.6z M17,2.6
			c0.8,0.8,2.2,2.2,3.4,4.1c-1.1,0.1-2.3,0.2-3.4,0.2V2.6z M17,14.8V8.9c1.4,0,2.9-0.1,4.4-0.3c0.9,1.8,1.5,3.9,1.6,6.2H17z
			 M24.9,14.7c-0.1-2.5-0.7-4.7-1.5-6.6c1.1-0.2,2.3-0.6,3.4-0.9c1.8,2.1,2.9,4.7,3.1,7.5H24.9z"/>
				</g>
			</g>
			<path d="M26.6,22.8L26.6,22.8c-0.3,0-0.6,0.1-0.8,0.2c-0.1-0.8-0.8-1.4-1.6-1.4h-0.1c-0.3,0-0.5,0.1-0.7,0.2
	c-0.2-0.6-0.8-1.1-1.5-1.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1v-2.6c0-1-0.7-1.8-1.6-1.8h-0.1c-0.9,0-1.6,0.8-1.6,1.8v6.6l-0.7,0.6
	c-0.6,0.5-0.9,1.2-1,2c-0.1,0.8,0.1,1.6,0.6,2.2l2.3,3.1V34c0,0.8,0.6,1.4,1.2,1.4h5.6c0.7,0,1.2-0.6,1.2-1.4v-1.2
	c0.7-1,1.1-2.3,1.1-3.6v-4.7C28.2,23.6,27.5,22.8,26.6,22.8z M27.2,29.3c0,1.1-0.4,2.2-1,3c-0.1,0.1-0.1,0.2-0.1,0.4V34
	c0,0.2-0.1,0.3-0.3,0.3h-5.6c-0.1,0-0.3-0.1-0.3-0.3v-1.4c0-0.1,0-0.2-0.1-0.4L17.5,29c-0.3-0.4-0.4-0.9-0.4-1.4
	c0-0.5,0.3-1,0.6-1.3l0.1-0.1v1.5c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-2.6l0,0v-6.8c0-0.4,0.3-0.7,0.6-0.7h0.1
	c0.3,0,0.6,0.3,0.6,0.7v4.2l0,0l0,0v3.5c0,0.3,0.2,0.5,0.5,0.5l0,0c0.3,0,0.5-0.2,0.5-0.5v-3.5c0-0.4,0.3-0.7,0.6-0.7h0.1
	c0.3,0,0.6,0.3,0.6,0.7v0.9l0,0l0,0v2.6c0,0.3,0.2,0.5,0.5,0.5l0,0c0.3,0,0.5-0.2,0.5-0.5v-2.6c0-0.4,0.3-0.7,0.6-0.7h0.1
	c0.3,0,0.6,0.3,0.6,0.7v1.1c0,0,0,0,0,0.1v1.5c0,0.3,0.2,0.5,0.5,0.5l0,0c0.3,0,0.5-0.2,0.5-0.5v-1.3c0,0,0,0,0-0.1
	c0-0.4,0.3-0.7,0.6-0.7h0.1c0.3,0,0.6,0.3,0.6,0.7L27.2,29.3L27.2,29.3z"/>
		</svg>
	);
};
