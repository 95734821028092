import React, { useState, useRef, useEffect } from "react";


import { ResourcesFilters } from './ResourcesFilters';
import { Resources } from './Resources';
import { Bubble } from "./Bubble";
import cloneDeep from 'lodash/cloneDeep';
import { Link } from "react-router-dom";

export const Home = (props: any) => {


    // resources.json: original and updated (checked)
    const [originalResources, setOriginalResources] = useState({});
    const [resources, setResources] = useState({});
    // chechek path of resources
    const [checkedPath, setCheckedPath] = useState('');
    // bubble container nad its diameter
    const bubbleContainer = useRef<HTMLInputElement>(null);
    const [diameter, setDiameter] = useState(0);
    const [showHelpModal, setShowHelpModal] = useState(false);



    // only first render
    useEffect(() => {

        const resourcesJson: any = props.resources;
        setOriginalResources(cloneDeep(resourcesJson));
        setResources(cloneDeep(resourcesJson));


        let diameter = 0;
        if (bubbleContainer.current) {
            // mobile view:
            if (window.innerWidth < 770) {
                diameter = bubbleContainer.current.offsetWidth;
            } else {
                diameter = bubbleContainer.current.offsetHeight;
                diameter -= 40;
            }
        }

        setDiameter(diameter);

        props.onBubblContainerDimensionChange(bubbleContainer.current ? bubbleContainer.current.offsetHeight : 0);

    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);




    //  when ResourceFilters changed
    //      updated resources.{X}.checked property accordingly: for subChild checked
    //      update CheckedPath (for Bubble and Resources)
    const onChangeHandler = (event: any) => {
        let target = event.target;
        let checkedPath = target.value.split("___");

        //console.log('onChangeHandler', checkedPath, target.checked, target.value);
        const newResources = togglePath(checkedPath, target.checked);
        //console.log('onChangeHandler.togglePath', newResources);
        if (!newResources) {
            return;
        }
        setResources(newResources);
        setCheckedPath(target.checked ? target.value : null);
    }


    // find child based on path and check all children tree accondingly
    const togglePath = (path: any, checked: boolean): any => {
        let newResources: any = cloneDeep(originalResources);
        console.log('togglePath', path, checked, newResources);

        // find deepest child corresponding to path
        let child = newResources;
        for (let i in path) {
            let findedChild = child.children.find((r: any) => r.name === path[i]);
            // if child has children means it has corresponding resource filters checkbox
            if (findedChild.children && findedChild.children.length > 0) {
                child = findedChild;
            }
        }

        if (child) {
            //console.log('find child', child.name, checked, path.length, child);
            child.checked = checked;
            if (child.children) {
                checkSubChildren(child, checked);
            }
        }
        return newResources;
    }

    // check all children
    const checkSubChildren = (child: any, checked: boolean) => {
        for (let i in child.children) {
            let subchild = child.children[i];
            subchild.checked = checked;
            if (child.children) {
                checkSubChildren(subchild, checked);
            }
        }
    }

    // return path array from Bubble node 
    const pathFromNode = (node: any) => {
        //let path = node.children && node.children.length > 0 ? [node.data.name] : [];
        let path = [node.data.name];
        while (node.parent) {
            node = node.parent;
            path.unshift(node.data.name);
        }
        path.shift();
        return path;
    }


    // when Resources changed (checked/unchecked) reflect behaviour in Bubble and ResourceFilters
    const onResourceChangeHandler = (resourcePath: string) => {
        //console.log('onResourceCHangeHandler', resourcePath);
        let path = resourcePath.split("___");
        if (path.length > 0) {
            const newResources = togglePath(path, checkedPath !== resourcePath);
            setResources(newResources);
            setCheckedPath(checkedPath !== resourcePath ? path.join("___") : "");
        }
    }

    // when Bubble Zoom to some node: reflect behaviour ResourceFilters and Resources
    const onZoomHandler = (node: any) => {
        let path = pathFromNode(node);
        const newResources = togglePath(path,
            path.length === 0 // means zoom full out, so uncheck all with "false"
                ? false
                : true);
        setResources(newResources);
        setCheckedPath(path.join("___"));

    }

    return (
        <div className="flex flex-wrap h-full">
            <section className="w-full h-full
                                    md:w-2/5
                                    lg:w-1/4 lg:order-1 ">
                {(resources ? <ResourcesFilters
                    resources={resources}
                    onchange={(event: any) => onChangeHandler(event)} />
                    : '')}

            </section>

            <section className="w-full order-last pb-8 h-full
                                    md:w-full
                                    lg:order-2 lg:w-1/4">
                <div className="border-t border-neutral-200 pt-4 ml-2 "></div>
                <Resources resources={resources}
                    onchange={(resourcePath: string) => onResourceChangeHandler(resourcePath)}
                    checkedPath={checkedPath}
                />
            </section>

            <section className="w-full h-full
                                md:w-3/5
                                lg:w-1/2 lg:pl-8 lg:order-3 lg:pr-5">

                <div ref={bubbleContainer} className="border-t border-neutral-200 pt-4 ml-2 h-full">
                    <div className="flex justify-end align-middle">
                        <div className="text-right italic mr-2 mt-1">See <Link className="underline" to="/about-bubble">About</Link> for more details.</div>
                        {/* help icon and modal */}
                        <div className="text-right">
                            <button type="button" className="fas fa-question-circle text-xl text-gray-600 focus:outline-none" title="Help" onClick={() => setShowHelpModal(!showHelpModal)}></button>
                        </div>
                    </div>
                    <div className={(showHelpModal ? 'block bg-opacity-75 ' : 'hidden') + " fixed z-10 inset-0 overflow-y-auto"}
                        onClick={() => setShowHelpModal(!showHelpModal)}>
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 " aria-hidden="true"></div>
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <div className="">
                                                <p className="text-sm text-gray-500">
                                                    Click on the bubbles or on the cards to discover the resources. You can also browse the categories through the filters on the left.
                                                    <br />
                                                    See <Link className="underline" to="/about">About</Link> for details
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {diameter
                        ? <Bubble
                            diameter={diameter}
                            resources={resources}
                            checkedPath={checkedPath}
                            onzoom={(d: any) => onZoomHandler(d)}
                        /> : ''}
                </div>
            </section>
        </div>
    );
}

