
import React from "react";

export const AboutBubbleText = (props: any) => {


    return (
        <div>

            <p>
                Protein glycosylation is probably the most important post-translational modification in terms of the number of proteins modified and the diversity generated. Yet, its role is far from being understood. Glycans and glycoconjugates (glycoproteins and glycolipids), carbohydrate-binding proteins (lectins and antibodies) as well as the enzymes that are needed to synthesise or trim glycans are the main molecular actors in this overall picture. However,  a wide range of experimental techniques produce glyco-data that are most of the time acquired independently. For example, most glycan structures are solved after being cleaved off their natural support while most protein glycosylation sites are identified after removing the attached glycans. In the end, glycan, glycoconjugates and glycan-binding data accumulate at different paces and with poor interrelatedness, when obviously  the various parts need to be connected for understanding of the functional role of glycosylation.
                <br /><br />
                Glyco@Expasy is designed to facilitate (i) the use of bioinformatics in glycoscience and (ii) the relation between glycobiology and protein-oriented bioinformatics resources. It is  gradually populated with web-based resources developed in-house as well as externally. To help users in selecting an appropriate database or software tool, collected resources are categorised in a hierarchy of themes that classically represent the different fields of glycobiology and most importantly are associated with specific experimental methods. Each term on the left is preceded by a check box which, if ticked, triggers zooming in the bubble chart to focus on the theme of interest. The colour code is in the legend. Zooming out is operated when clicking outside the circle in focus. Zooming in is also possible through clicking on a circle of interest. All zooming operations systematically check or uncheck boxes in the hierarchy of terms on the left. Information regarding the resource(s) in a selected circle is displayed in the middle of the page, in the form of a short description, a link to the resource and a link to the related publication.
            </p>

        </div>


    );
}