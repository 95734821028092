
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Wheel } from "../wheel/Wheel";
import { AboutWheelText } from "./AboutWheelText";

export const AboutWheel = (props: any) => {

    const sectionContainer = useRef<HTMLInputElement>(null);
    const [diameter, setDiameter] = useState(0);


    useEffect(() => {
        setDiameter(sectionContainer.current ? sectionContainer.current.offsetWidth : 0);

    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);



    return (

        <div className="lg:flex">
            <div ref={sectionContainer} className="lg:w-1/2">
                {diameter > 0
                    ? <Wheel
                        resources={props.resources}
                        diameter={diameter}
                    /> : ''}
            </div>
            <div className="lg:w-1/2 bg-neutral-100 border-t border-neutral-200 p-4 mb-8">
                <div className="flex justify-end md:justify-between items-center flex-wrap mb-8">
                    <h2 className="mt-0">Interconnection of the resources</h2>
                    <div>
                        <Link className="bg-white border-2 mr-3 border-blue-300 py-2 px-3 rounded text-sm no-underline"
                            to="/resource-integration">Go to the page</Link>
                    </div>
                </div>
                <AboutWheelText></AboutWheelText>
            </div>
        </div>


    );
}